import React from "react"
// import { Link } from "gatsby"

class FormButton extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
		// コンストラクタの中で明示的にbind
		this.handleClick = this.handleClick.bind(this);
	}
  // This syntax ensures `this` is bound within handleClick.
  // Warning: this is *experimental* syntax.
  handleClick = () => {
    const d = document.querySelectorAll(`.delete_${this.props.name}`)
    d.forEach(e => {
      e.classList.remove(`delete_${this.props.name}`)
    });

    const a = document.querySelectorAll(`.${this.props.data}`)
    a.forEach(e => {
      e.classList.add(`delete_${this.props.name}`)
    });

    const b = document.querySelectorAll(`.${this.props.data2}`)
    b.forEach(e => {
      e.classList.add(`delete_${this.props.name}`)
    });
  }

  render() {
    return (
      <label className={this.props.type}>
        <input onClick={this.handleClick} ref={this.myRef} type="radio" name={this.props.name} value={this.props.value} data-scgroup={this.props.data} data-color={this.props.data2}/>
        <span>{this.props.children}</span>
      </label>
    );
  }
}

const FormBtn = ({children, name, value, type, data, data2}) => {

  return (
    <>
    <FormButton name={name} value={value} data={data} type={type} data2={data2}>{children}</FormButton>
    </>
  )
}

export default FormBtn;
