import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import FormBtn from "./formbtn"

const LashingForm = () => (
  <StaticQuery
    query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              siteUrl
              
            }
          }
        }
      `}
    render={data => {
      const drawerOpen = () => {
        const searchform = document.querySelector('.searchform')
        searchform.classList.toggle("open")
      }
      const drawerClose = () => {
        const searchform = document.querySelector('.searchform')
        searchform.classList.remove("open")
      }

      return (
        <div className="bonding_blet_search">
          <button onClick={drawerOpen} className="title">固縛用ベルト絞り込み検索</button>
          <div className="searchform">
            <form method="get" id="searchform1" action="https://www.slingbelt.com/products/list">
              <p>ベルトカラー</p>
              <div className="searchform_item">
                <FormBtn name="color" value="422" data="w35" data2="c3" type="h1 h3 h4 h5 w1 w3 sp1 sp2 h7 sp3">オレンジ</FormBtn>
                <FormBtn name="color" value="421" data="w50" data2="c1" type="h3 h4 w1 w2 sp1 sp2">OD(オリーブドラブ)</FormBtn>
                <FormBtn name="color" value="420" data="w50" data2="c1" type="h3 h4 w1 w2 sp1 sp2">グレー</FormBtn>
                <FormBtn name="color" value="419" data="w25" data2="c2" type="h1 h3 h5 w2 sp1 sp2 h7 sp3">イエロー</FormBtn>
                <FormBtn name="color" value="418" data="w50" data2="c1" type="h3 h4 w1 w2 sp1 sp2">ホワイト</FormBtn>
                <FormBtn name="color" value="417" data="w25" data2="c1" type="h3 w2 sp2">ブラック</FormBtn>
                <FormBtn name="color" value="416" data="w25" type="w2 sp1">ブルー</FormBtn>
                <FormBtn name="color" value="415" data="w25" type="w2 sp1 sp2">レッド</FormBtn>
                <FormBtn name="color" value="660" data="w50" data2="bc1" type="w1 w2 h0 h3 h4 h5 h6 sp1 sp2 h7 sp3">ブラック/グレー</FormBtn>
                <FormBtn name="color" value="661" data="w50" data2="bc1" type="w1 w2 h0 h3 h4 h5 h6 sp1 sp2 h7 sp3">ブラック/ホワイト</FormBtn>
                <FormBtn name="color" value="662" data="w50" data2="bc1" type="w1 w2 h0 h3 h4 h5 h6 sp1 sp2 h7 sp3">OD/ブラック</FormBtn>
                <FormBtn name="color" value="663" data="w50" data2="bc1" type="w1 w2 h0 h3 h4 h5 h6 sp1 sp2 h7 sp3">ホワイト/オレンジ</FormBtn>
                <FormBtn name="color" value="845" data="w50" data2="bc2" type="h0 h1 h4 h5 w2 sp1 sp2 h7 sp3">オレンジ/ブラック</FormBtn>
                <FormBtn name="color" value="844" data="w50" data2="bc2" type="h0 h1 h3 h4 h5 w2 sp1 sp2 h7 sp3">ブラック/イエロー</FormBtn>
                <FormBtn name="color" value="">選択しない</FormBtn>

              </div>
              <p>ベルトタイプ</p>
              <div className="searchform_item">
                <FormBtn name="hook" value="450" data="h1" type="c3 bc2 w1 w2 sp1">Rフック</FormBtn>
                <FormBtn name="hook" value="449" data="h2">Jフック</FormBtn>
                <FormBtn name="hook" value="851" data="h7">Jフック(シングル)</FormBtn>
                <FormBtn name="hook" value="448" data="h2">Iフック</FormBtn>
                <FormBtn name="hook" value="447" data="h1" type="c2 c3 bc2 w1 w2 sp1">RJフック</FormBtn>
                <FormBtn name="hook" value="446" data="h2">IJフック</FormBtn>
                <FormBtn name="hook" value="445" data="h3" type="c1 c2 c3 bc2 w1 w2 sp1 sp2 bc1">スナップフック</FormBtn>
                <FormBtn name="hook" value="444" data="h3" type="c1 c2 c3 bc2 w1 w2 sp1 sp2 bc1">フラットフック</FormBtn>
                <FormBtn name="hook" value="443" data="h5" type="c2 c3 w1 bc2 w2 sp1 sp2 bc1">デルタリング</FormBtn>
                <FormBtn name="hook" value="442" data="h0" type="bc2 bc1">エンドレス</FormBtn>
                <FormBtn name="hook" value="454" data="h4" type="w35 w50 w2 w3 bc2 sp1 sp2 bc1">タイダウン</FormBtn>
                <FormBtn name="hook" value="455" data="h4" type="w35 w50 w2 w3 bc2 sp1 sp2 bc1">サブベルト</FormBtn>
                <FormBtn name="hook" value="456" data="h4" type="w35 w50 w2 w3 bc2 sp1 sp2 bc1">カムバックルエンドレス</FormBtn>
                <FormBtn name="hook" value="457" data="h4" type="w35 w50 w2 w3 bc2 sp1 sp2 bc1">サインベルト</FormBtn>
                <FormBtn name="hook" value="459" data="h4" type="w35 w50 w2 w3 bc2 sp1 sp2 bc1">一斗缶とって！とってちゃん</FormBtn>

                <FormBtn name="hook" value="">選択しない</FormBtn>

              </div>
              <p>ベルト幅</p>
              <div className="searchform_item">
                <FormBtn name="belt_width" value="430" data="w1" type="w35 w50 h1 h2 h3 bc2 sp2 bc1">25mm</FormBtn>
                <FormBtn name="belt_width" value="429" data="w2" type="w25 w50 h1 h3 h4 bc2 sp1 sp2 bc1">35mm</FormBtn>
                <FormBtn name="belt_width" value="428" data="w3" type="w35 h4">50mm</FormBtn>
                <FormBtn name="belt_width" value="" data="group_belt_width">選択しない</FormBtn>

              </div>
              <p>固定側</p>
              <div className="searchform_item">

                <FormBtn name="fixed_side" value="427" data="group_fixed_side">0.5m</FormBtn>
                <FormBtn name="fixed_side" value="453" data="group_fixed_side">0.7m</FormBtn>
                <FormBtn name="fixed_side" value="426" data="group_fixed_side">1.0m</FormBtn>
                <FormBtn name="fixed_side" value="425" data="group_fixed_side">1.5m</FormBtn>
                <FormBtn name="fixed_side" value="424" data="group_fixed_side">2.0m</FormBtn>
                <FormBtn name="fixed_side" value="423" data="group_fixed_side">2.5m</FormBtn>
                <FormBtn name="fixed_side" value="" data="group_fixed_side">選択しない</FormBtn>
              </div>
              <p>巻取側</p>
              <div className="searchform_item">
                <FormBtn name="winding_side" value="441" data="group_winding_side">1m</FormBtn>
                <FormBtn name="winding_side" value="440" data="group_winding_side">2m</FormBtn>
                <FormBtn name="winding_side" value="439" data="group_winding_side">3m</FormBtn>
                <FormBtn name="winding_side" value="438" data="group_winding_side">4m</FormBtn>
                <FormBtn name="winding_side" value="437" data="group_winding_side">5m</FormBtn>
                <FormBtn name="winding_side" value="436" data="group_winding_side">6m</FormBtn>
                <FormBtn name="winding_side" value="435" data="group_winding_side">7m</FormBtn>
                <FormBtn name="winding_side" value="434" data="group_winding_side">8m</FormBtn>
                <FormBtn name="winding_side" value="433" data="group_winding_side">9m</FormBtn>
                <FormBtn name="winding_side" value="432" data="group_winding_side">10m</FormBtn>
                <FormBtn name="winding_side" value="431" data="group_winding_side">11m ~</FormBtn>
                <FormBtn name="winding_side" value="" data="group_fixed_side">選択しない</FormBtn>
              </div>
              <p>ベルト仕様</p>
              <div className="searchform_item">
                <FormBtn name="heat_resisting" value="458" data="sp1">耐熱</FormBtn>
                <FormBtn name="heat_resisting" value="459" data="sp2">オールステンレス</FormBtn>
                <FormBtn name="heat_resisting" value="616" data="sp3">積載車用</FormBtn>
                <FormBtn name="heat_resisting" value="" data="group_heat_resisting">選択しない</FormBtn>
              </div>

              <div className="searchbtn2018btn"><button type="submit" className="searchbtn2018" id="searchbtn2018">チェックした商品をさがす</button></div>

            </form>
            <button onClick={drawerClose} className="closebtn">検索ウィンドウを閉じる</button>
          </div>

        </div>
      );
    }}
  />
);

export default LashingForm;