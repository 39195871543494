import * as React from "react"
import Layout from "../components/layout"
import LashingForm from "../components/lashingform"
import Swiper from "react-id-swiper"
import { graphql } from 'gatsby';
import Img from "gatsby-image"

const sliderParams = {
    slidesPerView: "auto",
    centeredSlides: true,
    effect: 'fade',
    autoplay: true,
  }
  
export default function Lashing({ data }) {
    const seodate = data.datoCmsLp
    // const seodate = {
    //     title: lpdata.title,
    //     seo:{
    //       description: "aet",
    //       image: {
    //         url: "ppp",
    //       }
    //     },
    //     slug: lpdata.slug,
    //   }
    return (
      <>
        <Layout seo={seodate} >
			
        <div className="typography">
		<LashingForm></LashingForm>
		<h1 className="title">ラッシングベルト<span className="english subtitle">LASHING BELT</span></h1>
		
		<div className="layout2">
			<div className="box1">
				<h2 className="title">商品説明</h2>
				<p>あらゆる荷物の固定・荷崩れ防止に使用する、やさしくがっちりと固定するラチェットバックル式ベルトです。 材質は摩耗に強いポリエステル製で、耐水性、耐候性に優れています。 小さな力で大きな荷締めでも簡単・スピーディーに固縛し作業効率UP！
					この商品の標準仕様は、右固定仕様（向かって右側に固定側・左側に巻取側で使用）です。左固仕様（向かって左側に固定側・右側に巻取側で使用）をご希望のお客様は、注文時のコメント欄に「左固定仕様希望」とご記入下さい。 </p>
			</div>
			<div className="box1">
				<h2 className="title">商品詳細</h2>
				<div className="table_box">
					<table className="item_detail_01">
						<tbody>
						<tr><th>ベルト 固定側</th><td>150mm</td></tr><tr><th>ベルト 巻取側</th><td>1,800mm</td></tr><tr><th>ベルト幅</th><td>25mm</td></tr><tr><th>素材</th><td>原着糸ポリエステル100％</td></tr><tr><th>先端金具</th><td>破断荷重 0.8t</td></tr><tr><th>カムバックル</th><td>破断荷重　680kg</td></tr><tr><td colSpan="2">PL保険加入商品</td></tr><tr><td colSpan="2">引張強度試験済</td></tr>
						</tbody></table>
				</div>
			</div>
		</div>
		<div className="layout3">

			<div className="box1">
				<h2 className="title">ラチェットバックル</h2>
				<Img fluid={seodate.image[0].fluid} />

				<div className="table_box">

					<table>
						<tbody><tr className="center">
						 <td>L</td>
						 <td>W1</td>
						 <td>W2</td>
						 <td>H</td>
						</tr>
						<tr>
						 <td className="font_12">195mm</td>
						 <td className="font_12">53mm</td>
						 <td className="font_12">93mm</td>
						 <td className="font_12">58mm</td>
						</tr>
					   </tbody>
					</table>
				</div>
			</div>
			<div className="box1">
				<h2 className="title">フック</h2>
                <Img fluid={seodate.image[1].fluid} />

				<div className="table_box">
					<table>
						<tbody><tr className="center">
						 <td>L</td>
						 <td>W1</td>
						 <td>W2</td>
						 <td>H</td>
						 <td>T</td>
						</tr>
						<tr>
						 <td className="font_12">35mm</td>
						 <td className="font_12">72mm</td>
						 <td className="font_12">52mm</td>
						 <td className="font_12">13mm</td>
						 <td className="font_12">3mm</td>
						</tr>
					   </tbody>
					</table>
				</div>
			</div>
			<div className="box1 w100">
				<h2 className="title">商品仕様</h2>
			</div>
			<div className="box1">
				<h3>補償も万全　生産物賠償責任保険［PL保険］加入商品</h3>

				<p>製品の欠陥によって、その製品の消費者その他第三者が生命・身体または財産に被害を被った場合、その製品の製造・販売に関与した事業者が、被害者に対して負うべき法律上の損害賠償責任をＰＬ（製造物責任）といいます。</p>
			</div>
			<div className="box1">
				<h3>作業環境の改善　金具表面処理　三価クロム対応</h3>

				<p>弊社ラッシングベルトは全金具部の表面処理は三価クロムを使用しております。三価クロムは、六価クロムの最有力代替技術であり三価クロム塩を主成分とする為毒性はなく、作業環境が改善されます。

				</p>
				<p>金メッキ処理（六価クロム）。六価クロムは極めて強い毒性を持つことで知られており（※1）ＲｏＨＳ指令により指定値が定められています。</p>
			</div>
			<div className="box1">
				<h3>安全性の向上　ダブルセーフティロック</h3>

				<p>今までのラッシングベルトは荷物を固定してラチェットバックルの金具を締めても、ハンドル部に何かが当たるなどすると、ハンドルが開放する危険性がありました。</p>
				<p>
					ダブルセーフティロックはハンドルを全開放するときも、ハンドル部内にあるレバーを引かなくては動かない構造になっているので、安全度が非常に高い商品です。
				</p>
			</div>
		</div>
	</div>

            <Swiper {...sliderParams}>
            <div>Slide 1</div>
            <div>Slide 2</div>
            <div>Slide 3</div>
            <div>Slide 4</div>
            <div>Slide 5</div>
        </Swiper>
        </Layout>
      </>
    )
  }



export const query = graphql`
 query {
    datoCmsLp(slug: {eq: "lashing"}) {
        slug
        seo {
          description
          title
          image {
            url
          }
        }
        title
        image{
            fluid(maxWidth: 600, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
                ...GatsbyDatoCmsFluid
              }
        }
      }
 }

`